import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createStore } from 'vuex'

const packageVersion : string = process.env.PACKAGE_VERSION || '0.0.0';
const splits : string[] = packageVersion.split('.')
let appVersion : string;

if( splits[2] === '0'){
    appVersion = splits[0] + '.' + splits[1];     
}else{
    appVersion = packageVersion;   
}

const store = createStore({
    state () {
      return {
        packageVersion : appVersion
      }
    },
  })

createApp(App).use(router).use(store).mount('#app')
